@import '../../node_modules/material-design-icons-iconfont/dist/material-design-icons.css';

body {
    background-color: #F3F3F3;
    display: flex;
    flex-flow: column;
    font-family: 'Roboto', sans-serif;
    margin: 0px;
    overflow-y: scroll;
}

body .page-container {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

.recharts-tooltip-wrapper {
    z-index: 1000;
}
